import { ReactNode, useCallback, useState } from 'react';

import { message } from 'antd';
import Axios from 'axios';
import store from 'src/store/mainStore';
import moment from 'moment';

const timeout = 30000;

const DK = 'http://dksquad.ru';
const FV = 'https://full-vision.ru';
export const BASE_LINK = FV;
const BASE_LINK_API = `${BASE_LINK}/api/`;

interface ErrorRequest {
    isError: boolean;
    errorMessage: any;
}

export enum RequestTypes {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch'
}

export interface RequestHookTypes {
    link: string;
    method: RequestTypes;
    protectedLink?: boolean;
  }

interface IMessage {
    title: string;
    description?: string;
    icon?: ReactNode;
}

interface RequestDataTypes {
    getParams?: any;
    body?: any;
    config?: any;
    successMessage?: IMessage;
    errorMessage?: IMessage;
    errorCodeMessage?: boolean;
    addToLink?: string;
    onSuccess?: (res: any) => void;
    onError?: (err: any) => void;
    replaceBaseLink?: string;
    isMultipart?: boolean;
}

export enum ApiLinks {
    getTmpMessages = 'messages/tmp_messages.php',
    getCanceledMessages = 'messages/canceled_messages.php',
    getMessages = 'messages/messages.php',
    getRequests = 'messages/requests.php',
    getCode = 'auth/check_auth.php',
    setAuth = 'auth/check_code.php',
    dicts = 'dicts/get_dict.php',
    personsFromBase = 'persons/get_from_base.php',
    createEvent = 'events/create_event.php',
    updateEvent = 'events/update_event.php',
    getEvents = 'events/event_list.php',
    getAgents = 'events/agent_list.php',
    getFullEvent = 'events/get_full_event.php',
    getEventMedia = 'events/event_media.php',
    sendMessage = 'messages/send_message.php',
    getUserMessages = 'messages/get_user_messages.php',
    getResponsibles = 'persons/get_responsibles_by_zone.php',
    deteiledPerson = 'persons/get_person_detailed.php',
    eventListByPerson = 'events/event_list_by_person.php',
    personsByEvent = 'persons/person_in_event.php',

}

export const personsByEvent: RequestHookTypes = {
  link: ApiLinks.personsByEvent,
  method: RequestTypes.get,
  protectedLink: true,
};

export const eventListByPerson: RequestHookTypes = {
  link: ApiLinks.eventListByPerson,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getDicts: RequestHookTypes = {
  link: ApiLinks.dicts,
  method: RequestTypes.get,
  protectedLink: true,
};

export const deteiledPerson: RequestHookTypes = {
  link: ApiLinks.deteiledPerson,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getResponsibles: RequestHookTypes = {
  link: ApiLinks.getResponsibles,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getEventMedia: RequestHookTypes = {
  link: ApiLinks.getEventMedia,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getUserMessages: RequestHookTypes = {
  link: ApiLinks.getUserMessages,
  method: RequestTypes.get,
  protectedLink: true,
};

export const personsFromBase: RequestHookTypes = {
  link: ApiLinks.personsFromBase,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getAgents: RequestHookTypes = {
  link: ApiLinks.getAgents,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getCanceledMessages: RequestHookTypes = {
  link: ApiLinks.getCanceledMessages,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getTmpMessages: RequestHookTypes = {
  link: ApiLinks.getTmpMessages,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getMessages: RequestHookTypes = {
  link: ApiLinks.getMessages,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getRequests: RequestHookTypes = {
  link: ApiLinks.getRequests,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getFullEvent: RequestHookTypes = {
  link: ApiLinks.getFullEvent,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getEvents: RequestHookTypes = {
  link: ApiLinks.getEvents,
  method: RequestTypes.get,
  protectedLink: true,
};

export const getCode: RequestHookTypes = {
  link: ApiLinks.getCode,
  method: RequestTypes.post,
};

export const sendMessage: RequestHookTypes = {
  link: ApiLinks.sendMessage,
  method: RequestTypes.post,
  protectedLink: true,
};

export const updateEvent: RequestHookTypes = {
  link: ApiLinks.updateEvent,
  method: RequestTypes.post,
  protectedLink: true,
};

export const setAuth: RequestHookTypes = {
  link: ApiLinks.setAuth,
  method: RequestTypes.post,
};

export const createEvent: RequestHookTypes = {
  link: ApiLinks.createEvent,
  method: RequestTypes.post,
  protectedLink: true,
};

const createParams = (obj: any): string => {
  if (!obj || !Object.keys(obj).length) {
    return '';
  }
  let compiledParams = '?';

  for (const key in obj) {
    if (obj[key]) {
      compiledParams += `${key}=${obj[key]}&`;
    }
  }
  compiledParams = compiledParams.slice(0, -1);
  return compiledParams;
};

export default function useRequest({ link, method, protectedLink = false }: RequestHookTypes) {
  const { logout } = store;

  const backendLink = BASE_LINK_API;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorRequest>({ isError: false, errorMessage: '' });
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);

  const { isError } = error;

  const dropState = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setError({ isError: false, errorMessage: '' });
    setResult(null);
  };

  const fetchRequest = async ({
    body,
    getParams,
    config,
    successMessage,
    errorMessage,
    onSuccess,
    onError,
    errorCodeMessage,
    addToLink,
    replaceBaseLink,
    isMultipart,
  }: RequestDataTypes) => {
    const { signature } = store;
    const fullLink = `${replaceBaseLink || backendLink}${link}${addToLink || ''}${createParams(getParams)}`;

    const headers = {
      'Content-type': isMultipart ? 'multipart/form-data' : 'application/json',
      ...(protectedLink && { Authorization: signature }),
      Accept: isMultipart ? 'multipart/form-data' : 'application/json',
    };
    const axiosInstance = Axios.create({
      timeout,
      headers,
    });

    try {
      let res: any = null;
      switch (method) {
        case 'get':
          res = await axiosInstance.get(fullLink);
          break;
        case 'post':
          res = await axiosInstance.post(fullLink, body, config);
          break;
        case 'put':
          res = await axiosInstance.put(fullLink, body, config);
          break;
        case 'patch':
          res = await axiosInstance.patch(fullLink, body, config);
          break;
        case 'delete':
          res = await axiosInstance.delete(fullLink, body);
          break;
        default:
          break;
      }

      if (successMessage) {
        const { description = '' } = successMessage;
        message.success({
          content: description,
          icon: <></>,
        });
      }
      setResult(res.data);
      setIsSuccess(true);
      onSuccess && onSuccess(res.data);
      setIsLoading(false);
    } catch (err: any) {
      if (err.response.status === 401) {
        logout();
      }

      if (errorCodeMessage) {
        message.error(err.err_code);
      }

      if (errorMessage) {
        const { description = '', icon } = errorMessage;
        message.error({
          content: description,
          // @ts-ignore
          ...(icon || <></>),
        });
      }

      onError && onError(err);
      setError({
        isError: true,
        errorMessage: err.response || 'Empty error data',
      });
      setIsLoading(false);
    }
  };

  const fetch = useCallback(
    ({
      body = {},
      getParams = {},
      config = {},
      successMessage,
      errorMessage,
      onError,
      onSuccess,
      errorCodeMessage,
      addToLink,
      replaceBaseLink,
      isMultipart,
    }: RequestDataTypes) => {
      setError({ isError: false, errorMessage: '' });
      setIsLoading(true);
      fetchRequest({
        body,
        getParams,
        config,
        successMessage,
        errorMessage,
        onError,
        onSuccess,
        errorCodeMessage,
        addToLink,
        replaceBaseLink,
        isMultipart,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isLoading,
    isError,
    isSuccess,
    fetch,
    result,
    dropState,
    error,
  };
}
