/* eslint-disable max-len */
import {
  computed,
  decorate, observable, toJS,
} from 'mobx';
import LocalStorage from 'src/utils/LocalStorage';
import { v4 as uuid } from 'uuid';

export interface IEvent {
  got_ts: any;
  brand: string;
  model: number;
  color: number;
  manufacutre_year: number;
  vin: string;
  gov_num: string;
  trailer_num: string;
  stikers: string;
  frame: string;
  comment_auto: string;
  // [x: string]: any;
  got_person: boolean;
  mobile: string;
  phone2: string;
  phone3: string;
  instagram: string;
  telegram: string;
  vk: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  dob: string;
  born_addr: string;
  nation: string;
  passport: string;
  inn: number;
  snils: number;
  current_addr: string;
  work_addr: string;
  executive: string;
  title: string;
  oper_comment: string;
  type: string;
  type_key: string;
  time: string;
  tg_id: number;
  from: IEventFrom;
  chat: IEventChat;
  bot: IEventBot;
  territory: string;
  step: string;
  location: IEventLocation;
  person_type: string;
  auto: string;
  person: string;
  social_link: string;
  deal: string;
  deal_type: string;
  comment: string;
  state: string;
  media?: (string)[] | null;
  key: string;
}
export interface IEventFrom {
  id: number;
  is_bot: boolean;
  first_name: string;
  username: string;
  language_code: string;
}
export interface IEventChat {
  id: number;
  first_name: string;
  username: string;
  type: string;
}
export interface IEventBot {
  id: number;
  is_bot: boolean;
  first_name: string;
  username: string;
}
export interface IEventLocation {
  address: string;
  location?: null;
}

class Store {
    event: IEvent = {} as IEvent;

    setEventData = (data: IEvent) => this.event = { ...data };

    eventHandle = (data: any, key: any) => this.event[key] = data;

    dropEvent = () => this.event = {} as IEvent;
}

decorate(Store, {
  event: observable,
});

const eventStore = new Store();

export default eventStore;
