import React, { useEffect, useState } from 'react';

import eventStore from 'src/store/eventStore';
import {
  Button, Checkbox, Collapse, Divider,
} from 'antd';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

import { Box } from '../Box';
import TSEdit from './TSEdit';

const EventTS = ({ onChange, values }: any) => {
  const { event, eventHandle } = eventStore;
  const [tss, setTss] = useState<any[]>([]);

  const tssHandler = (data: any, index: number) => {
    const list = [...tss];
    list[index] = data;
    setTss([...list]);
  };

  const addTS = () => {
    const list = [...tss];
    list.push({ id: `${Math.random().toFixed(0)}`, first_name: '' });
    setTss([...list]);
  };

  const removePerson = (ind: any) => {
    const list = tss.filter((_, index: number) => index !== ind);
    setTss([...list]);
  };

  useEffect(() => {
    if (!tss) return;
    onChange(tss);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tss]);

  const personsListRender = tss
    .map((el: any, index: number) => ({
      key: el.id,
      label: `${el.brand || 'Не введено'} ${el.gov_num || ''} ${el.color || ''}`,
      children: <TSEdit onChange={(data: any) => tssHandler(data, index)} values={tss[index]} />,
      extra: <Button onClick={() => removePerson(index)}>Удалить</Button>,
    }));

  return (
    <Box padding={3}>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Checkbox defaultChecked={!!event.auto} value={event.got_ts} onChange={e => eventHandle(e.target.checked, 'ts_find')}>Не установлен</Checkbox>
      </Box>
      <Box direction="row">
        <Box width="200px">Описание авто от источника</Box>
        <Box>{event.auto}</Box>
      </Box>
      <Box direction="row">
        <Box width="200px">Описание личности:</Box>
        <Box>{event.person}</Box>
      </Box>
      <Box direction="row">
        <Box width="200px">Описание события:</Box>
        <Box>{event.deal}</Box>
      </Box>
      {personsListRender.length > 0 ? (
        <Box margin={[2, 0]}>
          <Collapse
            size="small"
            items={personsListRender}
          />
        </Box>
      ) : <></>}
      <Box margin={[1, 0]}>
        <Button onClick={addTS} icon={<PlusCircleOutlined />} type="primary">Добавить авто</Button>
      </Box>

    </Box>
  );
};

export default observer(EventTS);
