import { CSSProperties, forwardRef, PropsWithChildren } from 'react';

export type BoxProps = {
    padding?:
        | number
        | [number]
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    margin?:
        | number
        | [number]
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    direction?: CSSProperties['flexDirection'];
    align?: CSSProperties['alignItems'];
    justify?: CSSProperties['justifyContent'];
    flex?: CSSProperties['flex'];
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    maxHeight?: CSSProperties['maxHeight'];
    minHeight?: CSSProperties['minHeight'];
    overflow?: CSSProperties['overflow'];
    position?: CSSProperties['position'];
    className?: string;
    dataTestID?: string;
    display?: CSSProperties['display'];
    style?: CSSProperties;
    onClick?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
    onContextMenu?: any;
    onScroll?: any;
    onWheel?: any;
    id?: string;
    dataColorMode?: 'light' | 'dark';
}

export function calcOffset(padding: number | number[]): string {
  const resultGrid = Array.isArray(padding) ? padding : [padding];
  const pad = resultGrid.map(i => i * 8);

  return `${pad.join('px ')}px`;
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  (
    {
      padding = 0,
      margin = 0,
      direction = 'column',
      justify = 'normal',
      align = 'stretch',
      children,
      width,
      height,
      maxHeight,
      minHeight,
      flex,
      overflow,
      className,
      dataTestID,
      position,
      display = 'flex',
      style,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onContextMenu,
      onScroll,
      onWheel,
      dataColorMode,
      id,
    },
    ref,
  ) => {
    const styles = {
      padding: calcOffset(padding),
      margin: calcOffset(margin),
      display,
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      width,
      height,
      maxHeight,
      minHeight,
      overflow,
      flex,
      position,
      ...style,
    };

    return (
      <div
        id={id || ''}
        ref={ref}
        style={styles}
        className={className}
        data-testid={dataTestID}
        onClick={onClick && onClick}
        onMouseEnter={onMouseEnter && onMouseEnter}
        onMouseLeave={onMouseLeave && onMouseLeave}
        onContextMenu={onContextMenu && onContextMenu}
        onScroll={onScroll && onScroll}
        onWheel={onWheel && onWheel}
        data-color-mode={dataColorMode}
      >
        {children}
      </div>
    );
  },
);
