import React from 'react';

import {
  Layout, Menu, MenuProps,
} from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import { Box } from 'src/components/Box';
import {
  NavLink, Switch, useParams,
} from 'react-router-dom';
import PrivateRoute from 'src/components/PrivateRoute';
import UserMenu from 'src/components/UserMenu/UserMenu';
import SearchModal from 'src/components/SearchModal/SearchModal';
import useRequest, { getDicts } from 'src/hooks/useRequest';
import { useMount } from 'src/hooks';
import store from 'src/store/mainStore';

import EventPage from '../EventPage/EventPage';
import Events from '../Events/Events';
import Requests from '../Requests/Requests';
import Agents from '../Agents/Agents';
import Wanted from '../Wanted/Wanted';

import './styles.scss';

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
};

const layoutStyle = {
  overflow: 'hidden',
  width: '100%',
  height: '100%',
};

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Dashboard = () => {
  const { setDict, role } = store;
  const { fetch } = useRequest(getDicts);
  const params: any = useParams();

  const adminMenu = [
    getItem(
      <NavLink to="/dashboard/messages">
        Сообщения
      </NavLink>,
      'messages',
    ),
    getItem(
      <NavLink to="/dashboard/requests">
        Обратная связь
      </NavLink>,
      'requests',
    ),

    getItem(
      <NavLink to="/dashboard/agents">
        Источник
      </NavLink>,
      'agents',
    ),
    getItem(
      <NavLink to="/dashboard/events">
        События
      </NavLink>,
      'events',
    ),
    getItem(
      <NavLink to="/dashboard/wanted">
        Розыск
      </NavLink>,
      'wanted',
    ),
    getItem('Объекты', 'objects', <></>, [
      getItem(
        <NavLink to="/dashboard/cars">
          Автомобили
        </NavLink>,
        'cars',
      ),
      getItem(
        <NavLink to="/dashboard/trailers">
          Прицепы
        </NavLink>,
        'trailers',
      ),
      getItem(
        <NavLink to="/dashboard/addresses">
          Адреса
        </NavLink>,
        'addresses',
      ),
      getItem(
        <NavLink to="/dashboard/stikers">
          Наклейки
        </NavLink>,
        'stikers',
      ),
    ]),
  ];

  const adminRoutes = [
    <PrivateRoute path="/dashboard/messages" auth><EventPage /></PrivateRoute>,
    <PrivateRoute path="/dashboard/requests" auth><Requests /></PrivateRoute>,
    <PrivateRoute path="/dashboard/events" auth><Events /></PrivateRoute>,
    <PrivateRoute path="/dashboard/agents" auth><Agents /></PrivateRoute>,
    <PrivateRoute path="/dashboard/wanted" auth><Wanted /></PrivateRoute>,
  ];

  const operatorRoutes = [
    <PrivateRoute path="/dashboard/events" auth><Events /></PrivateRoute>,
  ];

  const operator_menu = [
    getItem(
      <NavLink to="/dashboard/events">
        События
      </NavLink>,
      'events',
    ),
  ];

  useMount(() => fetch({
    onSuccess(res) {
      setDict(res);
    },
  }));

  if (!role) {
    return <></>;
  }

  return (
    <Box width="100%">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <Box width="100vw" direction="row" justify="space-betwwen">
            <Menu
              defaultSelectedKeys={role === 1 ? ['messages'] : ['events']}
            // defaultOpenKeys={['usersSubs', 'ordersSubs']}
              mode="horizontal"
              theme="dark"
              style={{ minWidth: '80%' }}
              selectedKeys={[params.id]}
              items={role === 1 ? adminMenu : operator_menu}
            />
            <Box align="center" margin={[0, 4, 0, 0]} justify="center"><SearchModal el={{ last_name: '', first_name: '', middle_name: '', dob: null }} /></Box>
            <UserMenu />
          </Box>
        </Header>
        <Content className="dashMain">
          <Content style={{ maxWidth: 1800, margin: '0 auto' }}>
            <Switch>
              {(role === 1 ? adminRoutes : operatorRoutes).map((el: any) => (el)) }
            </Switch>
          </Content>
        </Content>
        {/* <Footer style={footerStyle}>Footer</Footer> */}
      </Layout>
    </Box>
  );
};

export default Dashboard;
