import React, { useEffect, useState } from 'react';

import {
  Button, Steps, theme, Image,
} from 'antd';
import {
  ModalForm,
} from '@ant-design/pro-components';
import { InteractionOutlined } from '@ant-design/icons';
import eventStore, { IEvent } from 'src/store/eventStore';
import useRequest, { BASE_LINK, createEvent, getResponsibles } from 'src/hooks/useRequest';
import { observer } from 'mobx-react';
import store from 'src/store/mainStore';

import EventMain from './EventMain';
import EventLocation from './EventLocation';
import EventPersons from './EventPersons';
import { Box } from '../Box';
import EventTS from './EventTS';
import EventEmployee from './EventEmployee';

type Props = {
  record: IEvent;
}

const CreateEventModal = ({ record }: Props) => {
  const { setEventData, dropEvent, event } = eventStore;
  const { fetch: getResponsiblesFetch } = useRequest(getResponsibles);

  const { uuid, getDictId, dictionaries } = store;
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const { fetch, isLoading } = useRequest(createEvent);
  const [open, setOpen] = useState<boolean>(false);

  const [fullData, setFullData] = useState<any>({
    base_data: record,
    event: {
      title: '',
      type: record.type,
      type_key: record.type_key,
      time: record.time,
      deal: record.deal,
      comment: record.comment,
      oper_comment: '',
      territory: 0,
      sub_territory: null,
    },
    agent: record.chat,
    media: record.media,
    location: {
      address: record.location?.address,
      location: record.location?.location,
    },
    person: [],
    employee: {
      primary_processing: uuid,
      watchers: [],
      responsible: null,
      executor: null,
    },
  });

  const mainHandler = (data: any, key: string) => {
    setFullData((prev: any) => ({ ...prev, [key]: data }));
  };

  useEffect(() => {
    setFullData((prev: any) => ({
      ...prev,
      event: {
        ...prev.event,
        territory: getDictId('places', record.territory.split('_')[0]),
        sub_territory: record.territory.split('_').length > 1 ? getDictId('places', record.territory.split('_')[1], true) : null,
      },
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries]);

  console.log('fullData', fullData);

  const steps = [

    {
      title: 'Событие',
      content: <EventMain onChange={(d: any) => mainHandler(d, 'event')} values={fullData.event} />,
    },
    {
      title: 'Адреса',
      content: <EventLocation onChange={(d: any) => mainHandler(d, 'location')} values={fullData.location} />,
    },
    {
      title: 'Участники',
      content: <EventPersons onChange={(d: any) => mainHandler(d, 'person')} values={fullData.person} />,
    },
    {
      title: 'Транспорт',
      content: <EventTS onChange={(d: any) => mainHandler(d, 'transport')} values={fullData.transport} />,
    },
    {
      title: 'Назначения',
      content: <EventEmployee onChange={(d: any) => mainHandler(d, 'employee')} values={fullData.employee} zone={fullData.event} />,
    },
    /*     {
      title: 'Медиа',
      content: 'Last-content',
    }, */
    /*  {
      title: 'Сводная информация',
      content: 'Last-content',
    }, */
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const sendData = () => {
    console.log('fullData', fullData);
    fetch({
      body: fullData,
      onSuccess() {
        setOpen(false);
      },
    });
  };

  const setEvent = () => {
    setEventData(record);
  };

  const items = steps.map(item => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <ModalForm
      title="Сохранение события"
      trigger={(
        <Button type="primary" onClick={setEvent}>
          <InteractionOutlined />
        </Button>
        )}
      width="90vw"
      style={{ marginTop: 10 }}
      autoFocusFirstInput
      open={open}
      onOpenChange={state => setOpen(state)}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => dropEvent(),
      }}
      submitter={false}
    >

      <Steps current={current} items={items} />
      <div style={contentStyle}>
        <Box padding={2} direction="row" style={{ flexWrap: 'wrap', gap: 20 }}>
          {(event.media || []).map((el: string) => {
            if (el.includes('.oga')) {
              return (
              // eslint-disable-next-line jsx-a11y/media-has-caption
                <audio src={`${BASE_LINK}${el}`} controls />
              );
            }
            return (
              <Image
                width={70}
                src={`${BASE_LINK}${el}`}
              />
            );
          })}
        </Box>
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
      {/*       <div>
        {JSON.stringify(event, null, '\t')}
      </div> */}
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Далее
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={sendData} loading={isLoading} disabled={isLoading}>
            Отправить данные
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Назад
          </Button>
        )}
      </div>
    </ModalForm>
  );
};

export default observer(CreateEventModal);
