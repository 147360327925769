import React, { useState } from 'react';

import {
  Button, Form, Input, Typography,
} from 'antd';
import { Box } from 'src/components/Box';
import './styles.scss';
import useRequest, { getCode, setAuth } from 'src/hooks/useRequest';
import store from 'src/store/mainStore';
import { observer } from 'mobx-react';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
  code?: number;
};

const Auth: React.FC = () => {
  const { setSignature, setRole } = store;
  const { fetch, result } = useRequest(getCode);
  const { fetch: sendCode } = useRequest(setAuth);

  const [isSend, setIsSend] = useState<boolean>(false);

  const onFinish = (values: any) => {
    if (isSend) {
      sendCode({
        body: {
          code: values.code,
          keychain: result.uuid,
        },
        onSuccess(res) {
          setSignature(`${result.exp}:${result.uuid}:${res.signature}`);
          setRole(result.role);
          window.location.pathname = '/dashboard/messages';
        },
      });
      return;
    }

    fetch({
      body: {
        login: values.username,
        pwd: values.password,
      },
      onSuccess() {
        setIsSend(true);
      },
    });
  };

  return (
    <Box className="auth" align="center">
      <Box className="authContainer">
        <Box align="center">
          <Typography.Title level={3} style={{ color: '#fff' }}>
            Вход
          </Typography.Title>
        </Box>
        <Box>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >

            {!isSend && (
            <>
              <Form.Item<FieldType>
                style={{ color: 'red' }}
                label="Никнейм"
                name="username"
                // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>

              <Form.Item<FieldType>
                label="Пароль"
                name="password"
                // rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password style={{ width: 350 }} />
              </Form.Item>
            </>
            )}
            {isSend && (
            <Form.Item<FieldType>
              style={{ color: 'red' }}
              label="Разовый код"
              name="code"
              rules={[{ required: true, message: 'Please input your code!' }]}
            >
              <Input type="number" style={{ width: 350 }} />
            </Form.Item>
            )}
            <Box margin={[5, 0, 0, 14]}>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {isSend ? 'Отправить код' : 'Получить код'}
                </Button>
              </Form.Item>
            </Box>

          </Form>

        </Box>
      </Box>
    </Box>

  );
};

export default observer(Auth);
