import React from 'react';

import {
  Divider, Layout, Table, TableProps, Tag, Tooltip, Typography,
} from 'antd';
import { useMount } from 'src/hooks';
import useRequest, { getAgents, getEvents } from 'src/hooks/useRequest';
import { Box } from 'src/components/Box';
import {
  PaperClipOutlined, MoreOutlined, HomeOutlined, AlertOutlined, UserOutlined, WarningOutlined, ExclamationOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import AgentModal from 'src/components/AgentModal/AgentModal';

moment.locale('RU');
const { Header, Content, Footer } = Layout;
interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }

const Wanted = () => {
  const COLUMNS: TableProps<DataType>['columns'] = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',

    },
    {
      title: 'Telegram',
      dataIndex: 'tg_id',
      key: 'tg_id',

    },
    {
      title: 'Псевдоним',
      dataIndex: 'psevdo',
      key: 'psevdo',

    },
    {
      title: 'Событий',
      dataIndex: 'event_count',
      key: 'event_count',

    },
    {
      title: 'username',
      dataIndex: 'username',
      key: 'username',

    },
    {
      title: 'first_name',
      dataIndex: 'first_name',
      key: 'first_name',

    },
    {
      title: 'Создан',
      dataIndex: 'created',
      key: 'created',

    },
    {
      title: 'language_code',
      dataIndex: 'id',
      key: 'id2',
      render: value => (
        <AgentModal id={Number(value)}>
          <UserOutlined />
        </AgentModal>
      ),
    },
  ];
  return (
    <Content>
      <Box margin={[3]} style={{ background: '#fff', borderRadius: '10px', height: '88vh', overflow: 'scroll' }} padding={[0, 3]}>
        <Typography.Title level={3}>В розыске</Typography.Title>
        <Divider />
        <Box align="center">
          <Typography.Text type="secondary">В разработке</Typography.Text>
        </Box>
      </Box>

    </Content>
  );
};

export default Wanted;
