import React, { useEffect, useState } from 'react';

import { ModalForm } from '@ant-design/pro-components';
import {
  Button, Collapse, Divider, Input, Spin,
} from 'antd';
import useRequest, { personsFromBase } from 'src/hooks/useRequest';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Box } from '../Box';
import './styles.scss';
import PersonEditModal from '../CreateEvent/PersonEditModal';

interface Props {
  onClick?: (d: any) => void;
  el?: {
    last_name: string;
    first_name: string;
    middle_name: string;
    dob: string | null;
  };
}

const BLock = ({ data, onClick, isBase }: any) => (
  <Box>
    {data.list.map((el3: any) => (
      <Box direction="row" key={el3.id} className="searchList" padding={[3, 2]}>
        <Box width="100%">
          <Box direction="row" style={{ gap: 20 }} align="center">
            <Box width="20%">{el3.f}</Box>
            <Box width="20%">{el3.i}</Box>
            <Box width="20%">{el3.o}</Box>
            <Box width="20%">{el3.dob}</Box>
          </Box>
          <Box>{el3.addr}</Box>
          <Box>{el3.tel}</Box>
        </Box>
        <Box>
          {onClick
          && (
          <Button
            icon={<CheckOutlined />}
            onClick={() => onClick({
              last_name: el3.f,
              first_name: el3.i,
              middle_name: el3.o,
              dob: moment(moment(el3.dob).format('YYYY-MM-DD')),
              current_addr: el3.addr,
              mobile: el3.tel,
              ...(isBase && ({ id: Number(el3.id) })),
            })}
          >
            Использовать
          </Button>
          )}
          {isBase && <PersonEditModal onChange={() => {}} initValues={el3} detailed ro buttonTitle="Просмотр" title="Данные персонажа" />}
        </Box>
      </Box>
    ))}
  </Box>
);

const SearchModal = ({ el, onClick }: Props) => {
  const { fetch, isLoading, result } = useRequest(personsFromBase);
  const [values, setValues] = useState<any>({});
  const [activeKey, setActiveKey] = useState<string[] | string>([]);

  const searchHandler = () => {
    fetch({
      getParams: values,
    });
  };

  useEffect(() => {
    if (!el) return;
    const { last_name, first_name, middle_name, dob } = el;
    setValues({
      f: last_name,
      i: first_name,
      o: middle_name,
      dr: dob ? moment(dob).format('DD-MM-YYYY') : '',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);

  const renderList = result ? result?.map((element: any) => ({
    key: element.link,
    label: `${element.comment || ''}: ${element.list.length}`,
    children: <BLock data={element} onClick={onClick} isBase={element.link === 'person'} />,
  })) : [];

  const mainHandler = (d: any, key: string) => {
    setValues((prev: any) => ({ ...prev, [key]: d }));
  };

  console.log('result', result);

  return (
    <ModalForm
      title="Поиск"
      trigger={(
        <Button type="primary">
          Поиск
        </Button>
      )}
      width="60vw"
      style={{ marginTop: 10 }}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {},
      }}
      submitter={false}
    >
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Фамилия</Box>
          <Input value={values.f} onChange={e => mainHandler(e.target.value, 'f')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Имя</Box>
          <Input value={values.i} onChange={e => mainHandler(e.target.value, 'i')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Отчество</Box>
          <Input value={values.o} onChange={e => mainHandler(e.target.value, 'o')} />
        </Box>
      </Box>

      <Box width="100%" padding={[2, 0]}>
        <Button type="primary" icon={<SearchOutlined />} loading={isLoading} onClick={searchHandler}>
          Искать
        </Button>
      </Box>

      <Divider orientation="center">Результаты</Divider>
      {
        isLoading && <Box><Spin /></Box>
      }
      {
        !result && !isLoading && <Box>Ничего не найдено</Box>
      }
      {
        result && (
        <Collapse
          className="collapse"
          activeKey={activeKey}
          onChange={setActiveKey}
          size="small"
          items={renderList}
        />
        )
      }
    </ModalForm>
  );
};

export default SearchModal;
