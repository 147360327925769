import React from 'react';

import {
  Divider, Layout, Table, TableProps, Tag, Tooltip, Typography,
} from 'antd';
import { useMount } from 'src/hooks';
import useRequest, { getUserMessages } from 'src/hooks/useRequest';
import { Box } from 'src/components/Box';
import moment from 'moment';

moment.locale('RU');
const { Content } = Layout;
interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }

const UsersMessages = ({ id, title = true, setTotal, limit = 100 }: any) => {
  const { fetch, result, isLoading } = useRequest(getUserMessages);

  useMount(() => {
    fetch({
      getParams: {
        page: 1,
        perPage: limit,
        ...(id && ({ id })),
      },
      onSuccess(res) {
        setTotal && setTotal(Number(res.paging));
      },
    });
  });

  const COLUMNS: TableProps<DataType>['columns'] = [
    {
      title: 'Создано',
      dataIndex: 'date_created',
      key: 'date_created',
      render: v => moment(v).format('DD.MM.YYYY HH:MM'),

    },
    {
      title: 'Отправлено',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      render: v => (v ? moment(v).format('DD.MM.YYYY HH:MM') : <Tag color="red">Не отправлено</Tag>),
    },
    {
      title: 'Текст',
      dataIndex: 'message',
      render: value => (
        <Tooltip title={value}>
          <Typography.Text type="secondary">
            {value?.slice(0, 70)}
            {value.length > 70 ? '...' : ''}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'От кого',
      dataIndex: 'pseudo',
      key: 'pseudo',
    },
    {
      title: 'Кому',
      dataIndex: 'to_id',
      key: 'to_id',
    },
  ];

  return (
    <Content>
      <Box margin={id ? [0] : [3]} style={{ background: '#fff', borderRadius: '10px', height: '88vh', overflow: 'scroll' }} padding={title ? [0, 3] : [0, 0]}>
        {title && <Typography.Title level={3}>Список событий</Typography.Title>}
        <Divider />
        <Table columns={COLUMNS} dataSource={result?.records} loading={isLoading} />
      </Box>

    </Content>
  );
};

export default UsersMessages;
