/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import {
  Card, Popover, Progress, Tag, Typography, Image,
  Button,
} from 'antd';
import {
  PaperClipOutlined, MoreOutlined, HomeOutlined, AlertOutlined, UserOutlined, WarningOutlined, ExclamationOutlined, MessageOutlined, DeleteOutlined,
} from '@ant-design/icons';
import { Box } from 'src/components/Box';
import './styles.scss';
import { BASE_LINK } from 'src/hooks/useRequest';
import CreateEventModal from 'src/components/CreateEvent/CreateEventModal';
import SmsModal from 'src/components/SmsModal/SmsModal';

type Props = {
    data: any;
    ind: number;
}

function EventBox({ data, ind }: Props) {
  // eslint-disable-next-line no-control-regex
  const datatext = data?.deal?.replace(new RegExp('\r\n', 'g'), '').replace(new RegExp('===', 'g'), '') || '';
  const getTags = (d: any) => {
    const arr = [];
    if (d.auto) arr.push(<Popover content={d.auto}><Tag style={{ width: 'auto' }}>Транспорт</Tag></Popover>);
    if (d.person) arr.push(<Popover content={d.person}><Tag style={{ width: 'auto' }}>Личность</Tag></Popover>);
    return arr;
  };

  const tags = getTags(data);

  const getPercents = (d: any) => {
    let result = 0;
    if (d.media?.length) result += 20;
    if (d.person?.length) result += 20;
    if (d.person_type === 'get') result += 10;
    if (d.deal?.length > 50) result += 10;
    if (d.location?.address?.length > 30) result += 20;
    return result;
  };

  const voiceEvent = data.media.filter((el: string) => el.includes('.oga'));
  return (
    <Card
      size="small"
      title={(
        <Box>
          <Box direction="row">
            <Tag>
              {ind + 1}
            </Tag>
            <Tag color={data.type === 'Важно' ? 'warning' : 'error'} icon={data.type === 'Важно' ? <WarningOutlined /> : <ExclamationOutlined />}>
              {data.type}
            </Tag>
            <Typography.Text type="secondary">
              {data.time}
            </Typography.Text>
          </Box>
        </Box>
      )}
      extra={<MoreOutlined />}
      style={{ width: 320, height: 450, margin: 20 }}
      actions={[
        <CreateEventModal record={data} />,
        <SmsModal id={Number(data.chat.id)} />,
        <Button><DeleteOutlined /></Button>,
      ]}
    >
      <Box position="relative" height={350}>
        <Box>
          <Typography.Text type="secondary">Источник:</Typography.Text>
          {`${data.chat.first_name} ${data.chat.username ? `@${data.chat.username}` : ''} ${data.chat.id}`}
        </Box>
        <Box style={{ boxSizing: 'border-box', overflow: 'hidden', textAlign: 'left' }}>
          <Typography.Text type="secondary">Описание:</Typography.Text>
          <Typography.Text>
            {datatext?.slice(0, 170)}
            {datatext.length > 170 ? '...' : ''}
          </Typography.Text>
          {voiceEvent.map((el: string) => <audio key={el} src={`${BASE_LINK}/${el}`} controls />)}
        </Box>
        <Box position="absolute" style={{ bottom: 0 }} width="100%">
          {tags.length > 0 ? (
            <Box direction="row" margin={[0, 0, 2, 0]}>
              {getTags(data).map((el: any) => el)}
            </Box>
          ) : null}
          {data.person?.length > 5 && (
          <Box direction="row">
            <UserOutlined />
            <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '5px', textAlign: 'left' }}>
              {data.person.slice(0, 40)}
              {data.person.length > 40 ? '...' : ''}
            </Typography.Text>
          </Box>
          )}
          <Box direction="row">
            <AlertOutlined />
            <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '5px', textAlign: 'left' }}>{data.territory}</Typography.Text>
          </Box>
          <Box direction="row" align="flex-start">
            <HomeOutlined />
            {!data.location?.address && !data.location?.location
            && <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '5px', textAlign: 'left' }}>Не установлен</Typography.Text>}
            {data.location?.address && <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '5px', textAlign: 'left' }}>{data.location?.address}</Typography.Text>}
            {data.location?.location && <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '5px', textAlign: 'left' }}>{data.location?.location}</Typography.Text>}
          </Box>
          <Progress percent={getPercents(data)} size="small" />
          <Box direction="row" justify="space-between" align="center">
            <Box>
              {data?.bot?.first_name ?? ''}
            </Box>
            <Box direction="row" align="center">
              <Box>{data.media?.length}</Box>
              <Popover
                content={(
                  <Box direction="row" style={{ flexWrap: 'wrap', gap: 20, maxWidth: '400px' }}>
                    {data.media.map((el: string) => {
                      if (el.includes('.oga')) {
                        return (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                          <audio key={el} src={`${BASE_LINK}/${el}`} controls />
                        );
                      } if (el.includes('.mp4')) {
                        return (
                          <video width="320" height="240" controls key={el} src={`${BASE_LINK}/${el}`}>
                            Your browser does not support the video tag.
                          </video>
                        );
                      }
                      return (
                        <Image
                          width={70}
                          height={70}
                          key={el}
                          src={`${BASE_LINK}${el}`}
                        />
                      );
                    })}
                  </Box>
               )}
              >
                <PaperClipOutlined />
              </Popover>
            </Box>

          </Box>
        </Box>

      </Box>

    </Card>
  );
}

export default EventBox;
