import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Box } from 'src/components/Box';
import {
  FileSyncOutlined, WarningOutlined, ExclamationOutlined, PaperClipOutlined, EditOutlined, UndoOutlined, SaveOutlined, UserDeleteOutlined,
} from '@ant-design/icons';
import { ModalForm, ProList } from '@ant-design/pro-components';
import {
  Button, Descriptions, Tag, Typography, Image,
  Input,
  Select,
  Divider,
} from 'antd';
import useRequest, {
  getEventMedia, getFullEvent, getResponsibles, personsByEvent, updateEvent,
} from 'src/hooks/useRequest';
import store, { DictItemType } from 'src/store/mainStore';
import moment from 'moment';
import PersonEditModal from 'src/components/CreateEvent/PersonEditModal';

import EventMainModal from './EventMainModal';

type Props = {
    editable?: boolean;
    id?: boolean;
}
const values22 = {
  id: '65',
  title: 'Какой то ти',
  deal: 'В рабочем чате появились вот такие поздравление. Тех кто поставил отметку нравится сделал скриншоты.\r\n',
  event_time: '2024-04-29 08:39:34',
  created_at: '2024-06-18 13:21:47',
  event_power: 'warning',
  alert_key: 'CRIMEA_UBK',
  event_type: '10',
  sub_event_type: '18',
  oper_comment: 'Надо проверить',
  event_type_name: 'Внешняя атрибутика',
  sub_event_type_name: 'Флаг/государственная символика',
  comment: 'Проверьте их',
  location: '',
  address: 'Ул. Умельцев',
  // eslint-disable-next-line max-len
  medias: 'c4zNadfL98Z9N9fzq469xzea6Qs0aaQc,53q58dz6q45c377413370976za6yad85,80c5d71ecQ5xe1a7x33x2cf0c8z1feQ0,d95Q5Q1y5Q1aa27zzc10f3931ez4N442,2LaZ8263qZ4qq1QQs0yx3x0zLza0a523,x3ssz97Qy1ZQd65y6s1NQ1588678cL16',
};

const MaintEventModal = ({ editable, id }: Props) => {
  const { getDict, isAdminOrResp, dictionaries } = store;
  const { fetch, isSuccess, result: eventDetails } = useRequest(getFullEvent);
  const { fetch: fetchPersons, result: persons } = useRequest(personsByEvent);
  const { fetch: fetchMedia, result: medialist } = useRequest(getEventMedia);
  const { fetch: updateEventFetch, isLoading } = useRequest(updateEvent);
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<any>({});
  const { fetch: fetchResp, result } = useRequest(getResponsibles);

  const [editMode, setEditMode] = useState<string>('');
  const [editContent, setEditContent] = useState<any>();

  const getName = (idu: number) => ((idu && result) ? result.find((el: any) => el.id === idu)?.pseudo : '');

  const getNames = (ids: string) => {
    if (ids && result) {
      const list = ids.split(',').filter((el: string) => !!el.length);
      const names = list.map((el: any) => getName(Number(el)));
      return names.join(',');
    }
    return '---';
  };

  const editModeHandle = (filed: string, isArray: boolean = false) => {
    if (!filed.length) {
      setEditContent(null);
      setEditMode('');
    } else {
      setEditContent(isArray ? values[filed].split(',').filter((el: string) => !!el.length) : values[filed]);
      setEditMode(filed);
    }
  };

  useEffect(() => {
    if (id && open) {
      fetchPersons({
        getParams: {
          eid: id,
        },
      });
      fetch({
        getParams: { id },
        onSuccess(res) {
          setValues(res);
        },
      });
    }
  }, [fetch, fetchPersons, id, open]);

  useEffect(() => {
    if (isSuccess && open && isAdminOrResp) {
      fetchResp({
        getParams: {
          zone: eventDetails.territory,
          sub_zone: eventDetails.sub_territory,
        },
      });
    }
  }, [open, isSuccess, isAdminOrResp, fetchResp, eventDetails]);

  const savehandler = () => {
    updateEventFetch({
      body: {
        id,
        value: editContent,
        old: values[editMode],
        field: editMode,
      },
      onSuccess() {
        editModeHandle('');
        fetch({
          getParams: { id },
          onSuccess(res) {
            setValues(res);
          },
        });
      },
    });
  };

  const renderPersens = persons?.records.map((el: any) => ({ ...el, name: `${el.last_name} ${el.first_name} ${el.middle_name}`, desc: el.dob ? moment(el.dob).format('DD-MM-YYYY') : '' }));

  const EditButtons = ({ field }: any) => (editMode === field ? (
    <Box direction="row" style={{ gap: 10 }}>
      <SaveOutlined onClick={savehandler} style={{ marginTop: 20, cursor: 'pointer' }} />
      <UndoOutlined onClick={() => editModeHandle('')} style={{ marginTop: 20, cursor: 'pointer' }} />
    </Box>
  ) : <EditOutlined onClick={() => editModeHandle(field)} style={{ marginTop: 20, cursor: 'pointer' }} />);

  const EditButtonsHi = ({ field }: any) => (editMode === field ? (
    <Box direction="row" style={{ gap: 10 }}>
      <SaveOutlined onClick={savehandler} style={{ cursor: 'pointer' }} />
      <UndoOutlined onClick={() => editModeHandle('')} style={{ cursor: 'pointer' }} />
    </Box>
  ) : <EditOutlined onClick={() => editModeHandle(field)} style={{ cursor: 'pointer' }} />);

  const getMediaHandler = (list: string) => {
    fetchMedia({
      getParams: {
        list,
      },
    });
  };

  const responsiblesOptions = result?.filter((el: any) => el.role === 1).map((el: any) => ({ value: el.id, label: el.pseudo }));
  const empoyeeOptions = result?.filter((el: any) => el.role === 2).map((el: any) => ({ value: el.id, label: el.pseudo }));

  return (
    <ModalForm
      title="Событие"
      trigger={(
        <Button type="primary">
          <FileSyncOutlined />
        </Button>
        )}
      width="90vw"
      style={{ marginTop: 10 }}
      autoFocusFirstInput
      open={open}
      onOpenChange={state => setOpen(state)}
      modalProps={{
        destroyOnClose: true,
        // onCancel: () => dropEvent(),
      }}
      submitter={false}
    >
      <Box>
        <Typography.Title level={4}>{values.title}</Typography.Title>
        <Box>

          <Descriptions title="Ответственные">
            <Descriptions.Item label="Ответсвенный регион">
              <Box direction="row" style={{ gap: 10 }}>
                {editMode === 'responsible' ? (
                  <Select
                    // style={{ width, marginLeft: '20px' }}
                    value={editContent}
                    onChange={(val: number) => setEditContent(val)}
                    options={responsiblesOptions}
                  />
                )
                  : <Box>{getName(values.responsible)}</Box>}
                <EditButtonsHi field="responsible" />
              </Box>
            </Descriptions.Item>

            <Descriptions.Item label="Исполнитель">
              <Box direction="row" style={{ gap: 10 }} align="center">
                {editMode === 'executor' ? (
                  <Select
                    value={editContent}
                    style={{ width: 220 }}
                    onChange={(val: number) => setEditContent(val)}
                    options={empoyeeOptions}
                  />
                )
                  : <Box>{getName(values.executor)}</Box>}
                <EditButtonsHi field="executor" />
              </Box>
            </Descriptions.Item>
            <Descriptions.Item label="Наблюдатели">
              <Box direction="row" style={{ gap: 10 }} align="center">
                {editMode === 'watchers' ? (
                  <Select
                    value={editContent}
                    style={{ width: 220 }}
                    mode="tags"
                    tokenSeparators={[',']}
                    onChange={(val: number) => setEditContent(val)}
                    options={empoyeeOptions}
                  />
                )
                  : <Box>{getNames(values.watchers)}</Box>}
                {/* <EditButtonsHi field="watchers" /> */}
              </Box>

            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Общая информация">
            <Descriptions.Item label="Тип события" span={3}>
              {values.event_type_name}
              {` / ${values.sub_event_type_name || '---'}`}
            </Descriptions.Item>
            <Descriptions.Item label="Регион" span={3}>

              {`${getDict('places', values.territory)?.name || '---'} / ${getDict('places', values.territory, values.sub_territory)?.name || '---'}`}
            </Descriptions.Item>
            {isAdminOrResp
            && (
            <>
              <Descriptions.Item label="Создано">{values.event_time}</Descriptions.Item>
            </>
            )}
            <Descriptions.Item label="Передано в обработку">{values.created_at}</Descriptions.Item>
            <Descriptions.Item label="Статус">
              <Tag color={values.event_power === 'warning' ? 'warning' : 'error'} icon={values.event_power === 'warning' ? <WarningOutlined /> : <ExclamationOutlined />}>
                {values.event_power === 'warning' ? 'Важно' : 'Срочно'}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Адресс">
              <Box direction="row" style={{ gap: 10 }}>
                {editMode === 'address' ? <Input value={editContent} onChange={e => setEditContent(e.target.value)} /> : (
                  <Typography.Text type="secondary">
                    {values.address || '---'}
                  </Typography.Text>
                )}
                <EditButtonsHi field="address" />
              </Box>
            </Descriptions.Item>
            <Descriptions.Item label="Координаты">
              <Box direction="row" style={{ gap: 10 }}>
                {editMode === 'location' ? <Input value={editContent} onChange={e => setEditContent(e.target.value)} /> : (
                  <Typography.Text type="secondary">
                    {values.location || '---'}
                  </Typography.Text>
                )}
                <EditButtonsHi field="location" />
              </Box>
            </Descriptions.Item>

          </Descriptions>
          <Box>
            <Box direction="row" style={{ gap: 10 }} align="center">
              <Typography.Title level={5}>Описание события</Typography.Title>
              <EditButtons field="deal" />
            </Box>

            {editMode === 'deal' ? <Input.TextArea value={editContent} onChange={e => setEditContent(e.target.value)} /> : (
              <Typography.Text type="secondary">
                {values.deal}
              </Typography.Text>
            )}
            <Box direction="row" justify="space-between" margin={[3, 0]}>
              <Box width="49%" style={{ border: '1px solid grey', borderRadius: '10px' }} padding={[2]}>
                <Typography.Text strong>Коментарий источника</Typography.Text>
                <Typography.Text type="secondary">
                  {values.comment || '---'}
                </Typography.Text>
              </Box>
              <Box width="49%" style={{ border: '1px solid grey', borderRadius: '10px' }} padding={[2]}>
                <Box direction="row" align="center" justify="space-between" margin={[0, 0, 2, 0]}>
                  <Typography.Text strong>Коментарий оператора</Typography.Text>
                  {
                    editMode === 'oper_comment' ? (
                      <Box direction="row" style={{ gap: 20 }}>
                        <Button onClick={savehandler} type="primary" loading={isLoading} disabled={isLoading}><SaveOutlined /></Button>
                        <Button onClick={() => editModeHandle('')} danger><UndoOutlined /></Button>
                      </Box>
                    ) : editable ? <Button onClick={() => editModeHandle('oper_comment')}><EditOutlined /></Button> : null

                  }
                </Box>
                {editMode === 'oper_comment' ? <Input.TextArea value={editContent} onChange={e => setEditContent(e.target.value)} /> : (
                  <Typography.Text type="secondary">
                    {values.oper_comment}
                  </Typography.Text>
                )}
              </Box>

            </Box>

          </Box>
        </Box>
        {renderPersens?.length > 0 ? (
          <>
            <Divider orientation="left">Причастные лица</Divider>
            <ProList
              rowKey="name"
              dataSource={renderPersens}
              style={{ marginTop: '20px' }}
              rowHoverable={false}
              metas={{
                title: {
                  dataIndex: 'name',
                },
                description: {
                  dataIndex: 'desc',
                },
                actions: {
                  render: (text, row, index, action) => [
                    <PersonEditModal onChange={() => {}} buttonTitle="Просмотр" initValues={row} detailed ro />,
                  ],
                },
              }}
            />
          </>
        ) : null}
        {values?.medias?.length ? (
          <Box>
            <Button icon={<PaperClipOutlined />} onClick={() => getMediaHandler(values?.medias)}>{`Показать приложеные файлы: ${values?.medias?.split(',').length}`}</Button>
            <Box direction="row" style={{ gap: 20 }} padding={[4]}>
              {medialist?.map((el: any) => (
                <Image
                  width={70}
                  height={70}
                  key={el.filename}
                  src={el.bin_data}
                />
              ))}
            </Box>
          </Box>
        ) : <></>}
        {/* <EventMainModal /> */}
      </Box>

    </ModalForm>
  );
};

export default observer(MaintEventModal);

/*
<Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Тип события</Box>
        <Select
          style={{ width: 320, marginLeft: '-45px' }}
          placeholder="Выберите тип события"
          value={values.event_type}
          onChange={(val: number) => {
            mainHandler(null, 'event_sub_type');
            mainHandler(val, 'event_type');
          }}
          options={eventTypeOprions}
        />
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.event_sub_type}
          onChange={(val: number) => mainHandler(val, 'event_sub_type')}
          disabled={!values.event_type || eventSubType.length < 1}
          options={eventSubType}
        />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Регион</Box>
        <Select
          style={{ width: 320, marginLeft: '-45px' }}
          placeholder="Выберите тип события"
          value={values.territory}
          onChange={(val: number) => {
            mainHandler(null, 'sub_territory');
            mainHandler(val, 'territory');
          }}
          options={territoryOptions}
        />
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.sub_territory}
          onChange={(val: number) => mainHandler(val, 'sub_territory')}
          disabled={!values.territory || territorySubOptions.length < 1}
          options={territorySubOptions}
        />
      </Box>
*/
