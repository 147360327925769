import React, { useEffect, useState } from 'react';

import eventStore from 'src/store/eventStore';
import { Input, Select } from 'antd';
import { observer } from 'mobx-react';
import useRequest, { getResponsibles } from 'src/hooks/useRequest';

import { Box } from '../Box';

const EventEmployee = ({ onChange, values, zone }: any) => {
  const { fetch, result } = useRequest(getResponsibles);

  const [d, setData] = useState<any>(values);

  const mainHandler = (data: any, key: string) => {
    setData((prev: any) => ({ ...prev, [key]: data }));
  };

  useEffect(() => {
    if (zone?.territory === 0) return;

    fetch({
      getParams: {
        zone: zone.territory,
        sub_zone: zone.sub_territory,
      },
    });
  }, [fetch, zone]);

  useEffect(() => {
    if (!d) return;
    onChange(d);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  const responsiblesOptions = result?.filter((el: any) => el.role === 1).map((el: any) => ({ value: el.id, label: el.pseudo }));
  const empoyeeOptions = result?.filter((el: any) => el.role === 2).map((el: any) => ({ value: el.id, label: el.pseudo }));

  return (
    <Box padding={3}>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Ответственный по региону</Box>
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.responsible}
          onChange={(val: number) => mainHandler(val, 'responsible')}
          options={responsiblesOptions}
        />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Исполнитель</Box>
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.executor}
          onChange={(val: number) => mainHandler(val, 'executor')}
          options={empoyeeOptions}
        />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Наблюдатели</Box>
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.watchers}
          mode="tags"
          tokenSeparators={[',']}
          onChange={(val: number) => mainHandler(val, 'watchers')}
          options={empoyeeOptions}
        />
      </Box>
    </Box>
  );
};

export default observer(EventEmployee);
