import React from 'react';

import {
  Button, Card, Tag, Typography,
} from 'antd';
import {
  MessageOutlined, MoreOutlined,
} from '@ant-design/icons';
import SmsModal from 'src/components/SmsModal/SmsModal';

type Props = {
    data: any;
}

const RequestBox = ({ data }: Props) => (
  <Card
    size="small"
    title={(
      <>
        <Tag>
          {data.tg_id}
        </Tag>
        <Typography.Text type="secondary">
          {data.time}
        </Typography.Text>
      </>
      )}
    extra={<MoreOutlined />}
    style={{ width: 320 }}
    actions={[
      <SmsModal id={Number(data.tg_id)}>
        <Button><MessageOutlined /></Button>
      </SmsModal>,
    ]}
  >
    {data.text}
  </Card>
);

export default RequestBox;
