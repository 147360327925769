import { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';
import './assets/main.scss';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Button, Input, Modal } from 'antd';
import moment from 'moment';

import store from './store/mainStore';
import Auth from './pages/login/Auth';
import Dashboard from './pages/Dashboard/Dashboard';
import { Box } from './components/Box';
import PrivateRoute from './components/PrivateRoute';
import MainPage from './pages/MainPage/MainPage';

const App = () => {
  const {
    screenWidth, setScreenWidth, isAuth,
    // authModal, signature, setAuthModalHandler,
  } = store;
  const wrapperRef = useRef<undefined | any>();

  useEffect(() => {
    const resize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.style.width = `${screenWidth - 30}px`;
  }, [screenWidth]);

  /* if (isAuth) {
    const ts = Number(signature.split(':')[0]);
    const now = moment().unix();

    if (now > ts) {
      setAuthModalHandler(true);
    }
  }
  const handleOk = () => {
    console.log('ok');
  };

  const handleCancel = () => {
    console.log('handleCancel');
  }; */

  return (
    <Box width="100%">
      {/* <Modal
        title="Токен протух"
        open={authModal}
        footer={[
          <Button>Подтвердить</Button>,
          <Button>Выйти</Button>,
        ]}
      >
        <p>Необходимо ввести свежий код</p>
        <Box align="center" width="30%">
          <Box width="100%">Надпись на рамке</Box>
          <Input />
        </Box>
      </Modal> */}
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/putinme"><Auth /></Route>
        <PrivateRoute path="/dashboard/:id" auth={isAuth}><Dashboard /></PrivateRoute>
        <PrivateRoute path="/dashboard/" auth={isAuth}>
          {isAuth ? <Redirect to="/dashboard/events" /> : <Redirect to="/signin" />}
        </PrivateRoute>
        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

    </Box>
  );
};

export default observer(App);
