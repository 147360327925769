import React, { useEffect, useState } from 'react';

import {
  ModalForm, ProFormDatePicker, ProFormGroup, ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import {
  Alert, Button, Divider, Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import useRequest, { deteiledPerson, eventListByPerson } from 'src/hooks/useRequest';
import EventsByPerson from 'src/pages/Events/EventsByPerson';

import PersonEdit from './PersonEdit';
import SearchModal from '../SearchModal/SearchModal';
import { Box } from '../Box';

type Props = {
    onChange: (data: any) => void;
    title?: string;
    initValues: any;
    buttonTitle?: string;
    ro?: boolean;
    detailed?: boolean;
}

const PersonEditModal = ({
  onChange, title, buttonTitle, ro = false, initValues, detailed,
}: Props) => {
  const [readonly, setReadonly] = useState(ro);
  const [open, setOpen] = useState<boolean>(false);
  const { fetch } = useRequest(deteiledPerson);
  const [form] = useForm();

  const savehandler = (d: any) => {
    onChange(d);
    return true;
  };

  useEffect(() => {
    if (detailed && open) {
      fetch({
        getParams: {
          id: initValues.id,
        },
        onSuccess(res) {
          form.setFieldsValue(res);
        },
      });
    }
  }, [detailed, fetch, form, initValues.id, open]);

  return (
    <ModalForm
      title={title || 'Добавить участника'}
      readonly={readonly}
      form={form}
      trigger={(
        <Button type="primary">
          {buttonTitle || 'Редактировать'}
        </Button>
      )}
      width={detailed ? '90vw' : '1100px'}
      initialValues={initValues}
      style={{ marginTop: 10 }}
      autoFocusFirstInput
      onOpenChange={setOpen}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {},
      }}
      onFinish={async (v: any) => savehandler(v)}
      submitter={readonly ? false : {
        render: () => [
          <Button
            key="Сохранить"
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Сохранить
          </Button>,
        ],
      }}
    >
      {!detailed && (
      <Box direction="row" justify="space-between">
        <SearchModal onClick={(d: any) => form.setFieldsValue({ ...form.getFieldsValue(), ...d })} />
      </Box>
      )}
      {form.getFieldValue('danger_level') === 3 && (
      <Box style={{ background: 'red' }} padding={[0, 0, 0, 60]}>
        <Typography.Text strong style={{ color: 'white', fontSize: '30px' }}>ВНИМАНИЕ РОЗЫСК</Typography.Text>
      </Box>
      )}
      <Box>
        <Divider orientation="left">Установочные данные</Divider>
      </Box>
      <ProFormGroup>
        <ProFormText hidden name="id" />
        <ProFormText width="md" style={{ minWidth: '320px' }} name="last_name" label="Фамилия" placeholder="Введите фамилию" />
        <ProFormText width="md" name="first_name" label="Имя" placeholder="Введите имя" />
        <ProFormText width="md" name="middle_name" label="Отчество" placeholder="Введите отчество" />
        <ProFormDatePicker name="dob" label="Дата рождения" width="md" placeholder="Введите дату" />
        <ProFormText width="md" name="born_addr" label="Место рождения" placeholder="Введите Место рождения" />
        <ProFormText width="md" name="nation" label="Национальность" placeholder="Введите Национальность" />
        <ProFormTextArea width="xl" name="executive" label="Особые приметы" placeholder="Особые приметы" />

      </ProFormGroup>
      <Box>
        <Divider orientation="left">Телефоны</Divider>
      </Box>
      <ProFormGroup>
        <ProFormText width="md" name="mobile" label="Мобильный" placeholder="Введите фамилию" />
        <ProFormText width="md" name="phone2" label="Телефон2" placeholder="Телефон2" />
        <ProFormText width="md" name="phone3" label="Телефон3" placeholder="Телефон3" />
      </ProFormGroup>
      <Box>
        <Divider orientation="left">Соц сети</Divider>
      </Box>
      <ProFormGroup>
        <ProFormText width="md" name="instagram" label="Instagram" placeholder="Instagram" />
        <ProFormText width="md" name="telegram" label="telegram" placeholder="telegram" />
        <ProFormText width="md" name="vk" label="vk" placeholder="VK" />
      </ProFormGroup>
      <Box>
        <Divider orientation="left">Документы</Divider>
      </Box>
      <ProFormGroup>
        <ProFormText width="md" name="passport" label="Пасспорт" placeholder="Пасспорт" />
        <ProFormText width="md" name="inn" label="ИНН" placeholder="ИНН" />
        <ProFormText width="md" name="snils" label="СНИЛС" placeholder="СНИЛС" />
      </ProFormGroup>
      <Box>
        <Divider orientation="left">Адреса</Divider>
      </Box>
      <ProFormGroup>
        <ProFormText width="xl" name="current_addr" label="Адрес проживания" placeholder="Адрес проживания" />
        <ProFormText width="lg" name="work_addr" label="Адрес работы" placeholder="Адрес работы" />
      </ProFormGroup>
      <Box>
        <EventsByPerson uid={initValues.id} />
      </Box>
    </ModalForm>
  );
};

export default PersonEditModal;
