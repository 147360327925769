import React from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import store from 'src/store/mainStore';

import { Box } from '../Box';

const UserMenu = () => {
  const { logout } = store;
  return (
    <Box style={{ color: '#fff' }} justify="center">
      <LogoutOutlined style={{ fontSize: 25, cursor: 'pointer' }} onClick={logout} />
    </Box>
  );
};

export default UserMenu;
