import React, { useEffect, useState } from 'react';

import {
  Divider, Input, Typography, DatePicker,
} from 'antd';

import { Box } from '../Box';

type Props = {
    onChange: (d: any) => void;
    values: any;
}

export const DEFAULT_PERS = {
  last_name: '',
  first_name: '',
  middle_name: '',
  mobile: '',
  phone2: '',
  phone3: '',
  nation: '',
  born_addr: '',
  dob: null,
  instagram: '',
  telegram: '',
  vk: '',
  inn: '',
  snils: '',
  passport: '',
  current_addr: '',
  work_addr: '',
  executive: '',
};

const PersonEdit = ({ onChange, values }: Props) => {
  const [data, setData] = useState<any>(DEFAULT_PERS);

  const mainHandler = (d: any, key: string) => {
    setData((prev: any) => ({ ...prev, [key]: d }));
  };

  useEffect(() => {
    if (!data) return;
    onChange(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Box>
        <Divider orientation="left">Установочные данные</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Фамилия</Box>
          <Input value={values.last_name} onChange={e => mainHandler(e.target.value, 'last_name')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Имя</Box>
          <Input value={values.first_name} onChange={e => mainHandler(e.target.value, 'first_name')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Отчество</Box>
          <Input value={values.middle_name} onChange={e => mainHandler(e.target.value, 'middle_name')} />
        </Box>
      </Box>

      <Box>
        <Divider orientation="left">Телефоны</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Мобильный</Box>
          <Input value={values.mobile} onChange={e => mainHandler(e.target.value, 'mobile')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Телефон2</Box>
          <Input value={values.phone2} onChange={e => mainHandler(e.target.value, 'phone2')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Телефон3</Box>
          <Input value={values.phone3} onChange={e => mainHandler(e.target.value, 'phone3')} />
        </Box>
      </Box>
      <Box>
        <Divider orientation="left">Уроженец</Divider>
      </Box>

      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%" direction="row" justify="space-between">
            <Box>Дата рождения</Box>
            <Box direction="row" style={{ gap: 20 }}>
              <Typography.Text>схожих: 0</Typography.Text>
              <Typography.Link disabled>посмотреть</Typography.Link>
            </Box>
          </Box>
          <DatePicker value={values.dob} onChange={value => mainHandler(value, 'dob')} placeholder="Введите дату" style={{ width: '100%' }} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Место рождения</Box>
          <Input value={values.born_addr} onChange={e => mainHandler(e.target.value, 'born_addr')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Национальность</Box>
          <Input value={values.nation} onChange={e => mainHandler(e.target.value, 'nation')} />
        </Box>
      </Box>

      <Box>
        <Divider orientation="left">Соц сети</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Instagram</Box>
          <Input value={values.instagram} onChange={e => mainHandler(e.target.value, 'instagram')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">telegram</Box>
          <Input value={values.telegram} onChange={e => mainHandler(e.target.value, 'telegram')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">VK</Box>
          <Input value={values.vk} onChange={e => mainHandler(e.target.value, 'vk')} />
        </Box>
      </Box>

      <Box>
        <Divider orientation="left">Документы</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Пасспорт</Box>
          <Input value={values.passport} onChange={e => mainHandler(e.target.value, 'passport')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">ИНН</Box>
          <Input type="number" value={values.inn} onChange={e => mainHandler(e.target.value, 'inn')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">СНИЛС</Box>
          <Input value={values.snils} onChange={e => mainHandler(e.target.value, 'snils')} />
        </Box>
      </Box>

      <Box>
        <Divider orientation="left">Адреса</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="49%">
          <Box width="100%">Адрес проживания</Box>
          <Input value={values.current_addr} onChange={e => mainHandler(e.target.value, 'current_addr')} />
        </Box>
        <Box align="center" width="49%">
          <Box width="100%">Адрес работы</Box>
          <Input value={values.work_addr} onChange={e => mainHandler(e.target.value, 'work_addr')} />
        </Box>
      </Box>
      <Box direction="row" justify="space-between" margin={[3, 0, 0, 0]}>
        <Box align="center" width="100%">
          <Box width="100%">Особые приметы</Box>
          <Input.TextArea value={values.executive} onChange={e => mainHandler(e.target.value, 'executive')} />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonEdit;
