import moment from 'moment';

export function compare(a: any, b: any) {
  // const dateA = new Date(a.date);
  // const dateB = new Date(b.date);

  return 2 - 1;
}

export const sortArraByDate = (array: any[]) => array.sort((a: any, b: any) => +moment(b.time) - +moment(a.time));
