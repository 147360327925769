import React, { useEffect, useState } from 'react';

import eventStore from 'src/store/eventStore';
import { Input, Select } from 'antd';
import { observer } from 'mobx-react';
import store, { DictItemType } from 'src/store/mainStore';

import { Box } from '../Box';

const EventMain = ({ onChange, values }: any) => {
  const { dictionaries } = store;
  const { event } = eventStore;
  const [d, setData] = useState<any>(values);

  const mainHandler = (data: any, key: string) => {
    setData((prev: any) => ({ ...prev, [key]: data }));
  };

  const parceEventText = (data: string) => {
    if (!data) return '';
    let res = data.replace('\r\n', '\n');
    res = data.replace('===', '1.\n');
    return res;
  };

  const eventTypeOprions = dictionaries.events.main.map((el: DictItemType) => ({ value: el.id, label: el.name }));

  const eventSubType = dictionaries.events.sub.filter((el: DictItemType) => el.maintainer === values.event_type).map((el: DictItemType) => ({ value: el.id, label: el.name }));

  const territoryOptions = dictionaries.places.main.map((el: DictItemType) => ({ value: el.id, label: el.name }));

  const territorySubOptions = dictionaries.places.sub.filter((el: DictItemType) => el.maintainer === values.territory).map((el: DictItemType) => ({ value: el.id, label: el.name }));

  useEffect(() => {
    if (!d) return;
    onChange(d);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  if (!event?.type) {
    return <> </>;
  }

  console.log('values', values);
  return (
    <Box padding={3}>
      <Box direction="row" align="center">
        <Box width="250px">Заголовок</Box>
        <Input value={values?.title} onChange={e => mainHandler(e.target.value, 'title')} />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="210px">Сообщил</Box>
        <Box>{`${event.chat.first_name} @${event.chat.username} ${event.chat.id}`}</Box>
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Описание события</Box>
        <Input.TextArea value={parceEventText(values?.deal)} onChange={e => mainHandler(e.target.value, 'deal')} />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Тип события</Box>
        <Select
          style={{ width: 320, marginLeft: '-45px' }}
          placeholder="Выберите тип события"
          value={values.event_type}
          onChange={(val: number) => {
            mainHandler(null, 'event_sub_type');
            mainHandler(val, 'event_type');
          }}
          options={eventTypeOprions}
        />
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.event_sub_type}
          onChange={(val: number) => mainHandler(val, 'event_sub_type')}
          disabled={!values.event_type || eventSubType.length < 1}
          options={eventSubType}
        />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Регион</Box>
        <Select
          style={{ width: 320, marginLeft: '-45px' }}
          placeholder="Выберите тип события"
          value={values.territory}
          onChange={(val: number) => {
            mainHandler(null, 'sub_territory');
            mainHandler(val, 'territory');
          }}
          options={territoryOptions}
        />
        <Select
          style={{ width: 320, marginLeft: '20px' }}
          value={values.sub_territory}
          onChange={(val: number) => mainHandler(val, 'sub_territory')}
          disabled={!values.territory || territorySubOptions.length < 1}
          options={territorySubOptions}
        />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Комментарий источника</Box>
        <Input.TextArea value={parceEventText(values?.comment)} onChange={e => mainHandler(e.target.value, 'comment')} />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Комментарий оператора</Box>
        <Input.TextArea value={values?.oper_comment} onChange={e => mainHandler(e.target.value, 'oper_comment')} />
      </Box>
    </Box>
  );
};

export default observer(EventMain);
