import React, { useEffect, useState } from 'react';

import eventStore from 'src/store/eventStore';
import {
  Button,
  Typography,
} from 'antd';
import { observer } from 'mobx-react';
import { UserDeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ProList } from '@ant-design/pro-components';

import { Box } from '../Box';
import { DEFAULT_PERS } from './PersonEdit';
import PersonEditModal from './PersonEditModal';

const EventPersons = ({ onChange, values }: any) => {
  const { event } = eventStore;

  const [persons, setPersons] = useState<any[]>(values || [{ ...DEFAULT_PERS }]);

  const removePerson = (ind: any) => {
    const list = persons.filter((_, index: number) => index !== ind);
    setPersons([...list]);
  };

  useEffect(() => {
    if (!persons) return;
    onChange(persons);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons]);

  const addPerson = (data: any) => {
    const list = [...persons];
    list.push(data);
    setPersons(list);
  };

  const changePerson = (d: any, index: number) => {
    const list = [...persons];
    list[index] = { ...d };
    setPersons(list);
  };

  const renderPersens = persons.map((el: any) => ({ ...el, name: `${el.last_name} ${el.first_name} ${el.middle_name}`, desc: el.dob ? moment(el.dob).format('DD-MM-YYYY') : '' }));

  return (
    <Box padding={3}>
      <Box direction="row">
        <Box width="200px">Описание от источника</Box>
        <Box>{event.person}</Box>
      </Box>
      {/* <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Checkbox defaultChecked={event.person_type === 'get'} value={event.got_person} onChange={e => eventHandle(e.target.checked, 'got_person')}>Не установлена</Checkbox>
      </Box> */}
      <Box direction="row" align="center" justify="space-between">
        <Typography.Text strong style={{ fontSize: 18 }}>Участники</Typography.Text>
        <PersonEditModal onChange={addPerson} buttonTitle="Добавить" initValues={DEFAULT_PERS} />
      </Box>

      {renderPersens.length > 0 ? (
        <ProList
          rowKey="name"
          dataSource={renderPersens}
          style={{ marginTop: '20px' }}
          rowHoverable={false}
          metas={{
            title: {
              dataIndex: 'name',
            },
            description: {
              dataIndex: 'desc',
            },
            actions: {
              render: (text, row, index, action) => [
                <PersonEditModal onChange={(d: any) => changePerson(d, index)} buttonTitle="Редатировать" initValues={row} />,
                <Button danger onClick={() => removePerson(index)}><UserDeleteOutlined /></Button>,
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default observer(EventPersons);
