import React, { useState } from 'react';

import { ModalForm } from '@ant-design/pro-components';
import { Button, Input, notification } from 'antd';
import UsersMessages from 'src/pages/UsersMessages/UsersMessages';
import {
  MessageOutlined,
} from '@ant-design/icons';
import useRequest, { sendMessage } from 'src/hooks/useRequest';
import store from 'src/store/mainStore';

import { Box } from '../Box';

const SmsModal = ({ id, children }: any) => {
  const { uuid } = store;
  const { fetch: sendMessageFetch } = useRequest(sendMessage);

  const [open, setOpen] = useState<boolean>(false);
  const [sendText, setSendText] = useState<string>('');

  const send = () => {
    sendMessageFetch({
      body: {
        message: sendText,
        from: uuid,
        to: id,
      },
      onSuccess() {
        notification.success({
          key: 'kkk',
          message: 'Готово',
          description: 'Сообщение отправлено.',
        });
        setOpen(false);
      },
    });
  };

  return (
    <ModalForm
      title="Отправить сообщение"
      trigger={children || (
        <Button><MessageOutlined /></Button>
      )}
      width="80vw"
      style={{ marginTop: 10, maxHeight: '90vh' }}
      autoFocusFirstInput
      open={open}
      onOpenChange={setOpen}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {},
      }}
      submitter={false}
    >
      <Box style={{ maxHeight: '70vh', overflow: 'scroll' }}>
        <Box>
          <Input.TextArea value={sendText} onChange={(value: any) => setSendText(value.target.value)} style={{ minHeight: '120px' }} />
          <Box direction="row" margin={[2, 0]} style={{ gap: 20 }} justify="flex-end">
            <Button onClick={() => setSendText('')}>Очистить</Button>
            <Button type="primary" onClick={send} disabled={sendText.length < 10}>Отправить</Button>
          </Box>
        </Box>
        {open && id && <UsersMessages id={id} title={false} limit={5} />}
      </Box>
    </ModalForm>
  );
};

export default SmsModal;
