import React, { useEffect, useState } from 'react';

import eventStore from 'src/store/eventStore';
import { Input } from 'antd';
import { observer } from 'mobx-react';

import { Box } from '../Box';

const EventLocation = ({ onChange, values }: any) => {
  const { event } = eventStore;

  const [d, setData] = useState<any>();

  const mainHandler = (data: any, key: string) => {
    setData((prev: any) => ({ ...prev, [key]: data }));
  };

  useEffect(() => {
    if (!d) return;
    onChange(d);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  return (
    <Box padding={3}>
      <Box direction="row">
        <Box width="200px">Адрес события от источника</Box>
        <Box>{event.location?.address}</Box>
      </Box>
      <Box direction="row">
        <Box width="200px">Описание личности:</Box>
        <Box>{event.person}</Box>
      </Box>
      <Box direction="row">
        <Box width="200px">Описание события:</Box>
        <Box>{event.deal}</Box>
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Адрес события</Box>
        <Input.TextArea value={values?.address} onChange={e => mainHandler(e.target.value, 'address')} />
      </Box>
      <Box direction="row" margin={[2, 0, 0, 0]} align="center">
        <Box width="250px">Геопозиция</Box>
        <Input.TextArea value={values?.location || ''} onChange={e => mainHandler(e.target.value, 'location')} />
      </Box>
    </Box>
  );
};

export default observer(EventLocation);
