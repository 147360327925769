import React from 'react';

import './styles.scss';
import Phone from 'src/assets/images/natureforesttreesfog.png';
import { Box } from 'src/components/Box';
import { Typography } from 'antd';

const MainPage = () => (
  <Box width="100%" align="center" justify="center" className="bg">
    <Box align="center">
      <Typography.Title level={3}>Ты тоже так можешь</Typography.Title>
      <Typography.Title>Путешествуй - фотографируй - публикуй</Typography.Title>

      <Box position="relative">
        <Box position="absolute" style={{ right: 85, top: 80, width: 320, textAlign: 'center' }} align="center">
          <Typography.Title level={2}>PinThePlace</Typography.Title>
          <Typography.Title level={3}>Социальная сеть нового поколения</Typography.Title>
          <Typography.Title level={5}>Скоро....</Typography.Title>
        </Box>

        <img src={Phone} alt="alt" />
      </Box>
    </Box>
  </Box>
);

export default MainPage;
