import React from 'react';

import {
  Button,
  Divider, Layout, Table, TableProps, Tag, Tooltip, Typography,
} from 'antd';
import { useMount } from 'src/hooks';
import useRequest, { getEvents } from 'src/hooks/useRequest';
import { Box } from 'src/components/Box';
import {
  WarningOutlined, ExclamationOutlined, FileSyncOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import store from 'src/store/mainStore';

import MaintEventModal from './EventMainModal';

moment.locale('RU');
const { Content } = Layout;
interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }

const Events = ({ aid, title = true, setTotal }: any) => {
  const { isAdmin } = store;
  const { fetch, result } = useRequest(getEvents);

  useMount(() => {
    fetch({
      getParams: {
        page: 1,
        perPage: 100,
        ...(aid && ({ aid })),
      },
      onSuccess(res) {
        setTotal && setTotal(Number(res.paging));
      },
    });
  });

  const COLUMNS: TableProps<DataType>['columns'] = [
    {
      title: 'Обработано',
      dataIndex: 'created_at',
      key: 'created_at',
      render: v => moment(v).format('DD.MM.YYYY HH:MM'),

    },
    {
      title: 'Создано',
      dataIndex: 'event_time',
      key: 'event_time',
      render: v => moment(v).format('DD.MM.YYYY HH:MM'),
    },
    {
      title: '',
      dataIndex: 'event_power',
      render: value => (
        <Tag color={value === 'warning' ? 'warning' : 'error'} icon={value === 'warning' ? <WarningOutlined /> : <ExclamationOutlined />}>
          {value === 'warning' ? 'Важно' : 'Срочно'}
        </Tag>
      ),
    },
    {
      title: 'Тип события',
      dataIndex: 'event_type_name',
      key: 'event_type_name',
      render: (value, row: any) => (
        <Box style={{ gap: 10 }}>
          <Tag>{value}</Tag>
          {row.sub_event_type_name && <Tag>{row.sub_event_type_name}</Tag>}
        </Box>
      ),
    },
    {
      title: 'Описание',
      dataIndex: 'deal',
      key: 'deal',
      render: value => (
        <Tooltip title={value}>
          <Typography.Text type="secondary">
            {value?.slice(0, 70)}
            {value.length > 70 ? '...' : ''}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'Ком. оператора',
      dataIndex: 'oper_comment',
      key: 'oper_comment',

    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: id => <MaintEventModal id={id} />,
    },
  ];

  if (isAdmin) {
    COLUMNS.unshift({
      title: 'Источник',
      dataIndex: 'agent',
      key: 'agent',
      render: (value, row: any) => (
        <Tag>
          {value}
          {row.psevdo ? ` (${row.psevdo})` : ''}
        </Tag>
      ),
    });
  }
  return (
    <Content>
      <Box margin={aid ? [0] : [3]} style={{ background: '#fff', borderRadius: '10px', height: '88vh', overflow: 'scroll' }} padding={title ? [0, 3] : [0, 0]}>
        {title && <Typography.Title level={3}>Список событий</Typography.Title>}
        <Divider />
        <Table columns={COLUMNS} dataSource={result?.records} />
      </Box>

    </Content>
  );
};

export default Events;
