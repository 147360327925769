import React, { useState } from 'react';

import { Box } from 'src/components/Box';
import { useMount } from 'src/hooks';
import useRequest, { getCanceledMessages, getMessages, getTmpMessages } from 'src/hooks/useRequest';
import {
  Badge, Input, Space, Tabs, TabsProps,
  Typography,
} from 'antd';
import { sortArraByDate } from 'src/helpers';
// import { SearchOutlined } from '@ant-design/icons';

import EventBox from './EventBox';

const EventPage = () => {
  const [newList, setNewList] = useState<any[]>([]);
  const [processList, setProcessList] = useState<any[]>([]);
  const [canceledList, setCanceledList] = useState<any[]>([]);
  const [current, setCurrent] = useState<string>('new');

  const { fetch } = useRequest(getMessages);
  const { fetch: fetchTmp } = useRequest(getTmpMessages);
  const { fetch: fetchCanceled } = useRequest(getCanceledMessages);

  const items: TabsProps['items'] = [
    {
      key: 'process',
      label: <Badge count={processList.length || 0}><Box width={100}>В процессе</Box></Badge>,
      disabled: !processList.length,
    },
    {
      key: 'new',
      label: <Badge count={newList.length || 0}><Box width={100}>Новые</Box></Badge>,
      disabled: !newList.length,
    },
    /*  {
      key: 'canceled',
      label: <Badge count={canceledList?.length || 0}><Box width={100}>Отмененные</Box></Badge>,
      disabled: !canceledList.length,
    }, */
  ];

  useMount(() => {
    fetch({
      onSuccess(res) {
        setNewList(res?.records || []);
      },
    });
    fetchTmp({
      onSuccess(res) {
        setProcessList(res?.records || []);
      },
    });
    fetchCanceled({
      onSuccess(res) {
        setCanceledList(res?.records || []);
      },
    });
  });

  const currentList = current === 'new' ? newList : current === 'canceled' ? canceledList : processList;

  const onChange = (d: string) => setCurrent(d);

  return (
    <Box style={{ maxWidth: '80%', margin: '0 auto' }} padding={5} align="center">
      <Tabs defaultActiveKey="new" items={items} onChange={onChange} />
      {/* <Box direction="row" style={{ gap: 30 }}>
        <Box>
          <Box>
            <Typography.Text type="secondary">Поиск по номеру</Typography.Text>
          </Box>
          <Input type="search" prefix={<Box><SearchOutlined /></Box>} />
        </Box>
        <Box>
          <Box>
            <Typography.Text type="secondary">Поиск по источнику</Typography.Text>
          </Box>
          <Input type="search" prefix={<Box><SearchOutlined /></Box>} />
        </Box>
      </Box> */}
      <Space direction="horizontal" size={[50, 16]} wrap>
        {sortArraByDate(currentList).map((el: any, index: number) => (
          <EventBox data={el} ind={index} />
        ))}
      </Space>
    </Box>
  );
};

export default EventPage;
