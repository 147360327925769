import React, { useEffect, useState } from 'react';

import { ModalForm } from '@ant-design/pro-components';
import useRequest, { getAgents, getUserMessages } from 'src/hooks/useRequest';
import {
  Button, Card, Tabs, Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  MessageOutlined,
} from '@ant-design/icons';
import Events from 'src/pages/Events/Events';
import UsersMessages from 'src/pages/UsersMessages/UsersMessages';
import moment from 'moment';

import { Box } from '../Box';
import SmsModal from '../SmsModal/SmsModal';

type Props = {
    id: string | number;
    children?: any;
}

const AgentModal = ({ id, children }: Props) => {
  const { fetch, result } = useRequest(getAgents);
  const { fetch: lastMessages, result: messages } = useRequest(getUserMessages);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      fetch({
        getParams: {
          id,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const data = result ? result.records[0] : null;

  useEffect(() => {
    if (result?.records[0]?.tg_id) {
      lastMessages(({
        getParams: {
          id: result?.records[0]?.tg_id,
        },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const eventCount = result && Number(result.records[0]?.event_count);
  const tabList = [
    {
      key: 'events',
      label: 'events',
      disabled: result?.records[0]?.event_count < 1,
      children: eventCount ? <Box><Events aid={result.records[0].tg_id} title={false} /></Box> : <></>,
    },
    {
      key: 'messages',
      label: 'messages',
      disabled: result?.records[0]?.message_count < 1,
      children: result ? <UsersMessages id={result.records[0].tg_id} title={false} /> : <></>,
    },

  ];

  return (
    <ModalForm
      title="Поиск"
      trigger={children || (
        <Button type="primary">
          {id}
        </Button>
      )}
      width="90vw"
      style={{ marginTop: 10 }}
      autoFocusFirstInput
      open={open}
      onOpenChange={setOpen}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {},
      }}
      submitter={false}
    >
      <Box direction="row" justify="space-between">
        <Card style={{ width: '40%' }} title="Источник">
          <Box direction="row">
            <Box width="200px">Телеграм</Box>
            <Box>{data?.tg_id}</Box>
          </Box>
          <Box direction="row">
            <Box width="200px">Псевдоним</Box>
            <Box>{data?.psevdo || '---'}</Box>
          </Box>
          <Box direction="row">
            <Box width="200px">Сообщений обработано</Box>
            <Box>{data?.event_count || 0}</Box>
          </Box>
          <Box direction="row">
            <Box width="200px">Наличие в базе</Box>
            <Box>{data?.in_base ? 'Обнаружено' : 'Не обнаружено'}</Box>
          </Box>
          <Box direction="row">
            <Box width="200px">Добавлен</Box>
            <Box>{data?.created || ''}</Box>
          </Box>
        </Card>
        <Card
          style={{ width: '40%' }}
          title="Последние сообщения"
          extra={[
            <SmsModal id={Number(result?.records[0]?.tg_id)} />,
          ]}
        >
          {messages?.records.slice(0, 4).map((el: any) => (
            <Box direction="row" align="center" margin={[0, 0, 1, 0]}>
              <Box style={{ fontSize: 12 }} width="30%">{(el.delivery_date ? moment(el.delivery_date).format('DD.MM.YYYY HH:MM') : <Tag color="red">Не отправлено</Tag>)}</Box>
              <Box>
                <Tooltip title={el.message}>
                  <Typography.Text type="secondary">
                    {el.message?.slice(0, 40)}
                    {el.message.length > 70 ? '...' : ''}
                  </Typography.Text>
                </Tooltip>
              </Box>
            </Box>
          ))}

        </Card>
      </Box>

      <Box padding={[2, 3]}>
        <Tabs items={tabList} />
      </Box>

    </ModalForm>
  );
};

export default AgentModal;
