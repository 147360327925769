/* eslint-disable max-len */
import {
  computed, decorate, observable,
} from 'mobx';
import LocalStorage from 'src/utils/LocalStorage';

export type ILang = 'RU' | 'EN'

export const MOBILE_POINT_MENU = 768;
export const MOBILE_POINT_CONTENT = 414;

export type DictItemType = {
  id: number;
  name: string;
  maintainer?: number;
  point?: number;
  code?: string;
}

type MainSubType = {
  main: DictItemType[];
  sub: DictItemType[];
};

export type DictType = {
  cars: any[]; // TODO TYPE
  events: MainSubType;
  places: MainSubType;
}

class Store {
    screenWidth: number = window.innerWidth;

    setScreenWidth = (wid: number) => this.screenWidth = wid

    dictionaries: DictType = {} as DictType

    setDict = (dict: DictType) => this.dictionaries = dict

    authModal: boolean = false;

    setAuthModalHandler = (state: boolean) => this.authModal = state

    signature: string = LocalStorage.get('s') || ''

    role: number = LocalStorage.get('r') || ''

    setRole = (role: number) => {
      LocalStorage.set('r', Number(role));
      this.role = Number(role);
    }

    get isAdmin() {
      return this.role === 1;
    }

    get isAdminOrResp() {
      return [1, 3].includes(this.role);
    }

    get uuid() {
      return this.signature ? this.signature.split(':')[1] : null;
    }

    getDictId = (key: keyof DictType, code: string, isSub: boolean = false) => {
      if (!Object.keys(this.dictionaries).length) {
        return 0;
      }
      if (isSub) {
        return this.dictionaries[key].sub.find((el: DictItemType) => el.code === code)?.id;
      }
      return this.dictionaries[key].main.find((el: DictItemType) => el.code === code)?.id;
    }

    getDict = (key: keyof DictType, main: number, sub?: number) => {
      if (!Object.keys(this.dictionaries).length) {
        return {};
      }
      if (sub) {
        return this.dictionaries[key].sub.find((el: DictItemType) => el.id === sub && el.maintainer === main);
      }
      return this.dictionaries[key].main.find((el: DictItemType) => el.id === main);
    }

    setSignature = (sign: string) => {
      this.signature = sign;
      LocalStorage.set('s', sign);
    }

    logout = () => {
      LocalStorage.remove('s');
      LocalStorage.remove('r');
      window.location.pathname = '/';
    }

    get isAuth() {
      return !!this.signature;
    }
}

decorate(Store, {
  signature: observable,
  isAuth: computed,
});

const store = new Store();

export default store;
