import React, { useState } from 'react';

import { Box } from 'src/components/Box';
import { useMount } from 'src/hooks';
import useRequest, { getRequests } from 'src/hooks/useRequest';
import {
  Badge, Space, Tabs, TabsProps,
} from 'antd';
import { sortArraByDate } from 'src/helpers';

import RequestBox from './RequestBox';

const Requests = () => {
  const [newList, setNewList] = useState<any[]>([]);
  const [, setCurrent] = useState<string>('new');

  const { fetch } = useRequest(getRequests);

  const items: TabsProps['items'] = [
    {
      key: 'process',
      label: <Badge count={0}><Box width={100}>Обработанные</Box></Badge>,
      disabled: true,
    },
    {
      key: 'new',
      label: <Badge count={newList.length}><Box width={100}>Новые</Box></Badge>,
    },
  ];

  useMount(() => {
    fetch({
      onSuccess(res) {
        setNewList(res.records);
      },
    });
  });

  const currentList = newList;

  const onChange = (d: string) => setCurrent(d);

  return (
    <Box style={{ maxWidth: '80%', margin: '0 auto' }} padding={5}>
      <Tabs defaultActiveKey="new" items={items} onChange={onChange} />
      <Space direction="horizontal" size={[50, 16]} wrap>
        {sortArraByDate(currentList).map((el: any) => (
          <RequestBox data={el} />
        ))}
      </Space>
    </Box>
  );
};

export default Requests;
