import React, { useEffect, useState } from 'react';

import {
  Divider, Input,
  Select,
} from 'antd';
import store from 'src/store/mainStore';

import { Box } from '../Box';

type Props = {
    onChange: (d: any) => void;
    values: any;
}

const TSEdit = ({ onChange, values }: Props) => {
  const [data, setData] = useState<any>({});
  const { dictionaries } = store;

  const mainHandler = (d: any, key: string) => {
    setData((prev: any) => ({ ...prev, [key]: d }));
  };

  const brands = dictionaries.cars.map((el: any) => ({ label: el.name, value: el.name }));
  const currentBrand = dictionaries.cars.find((el: any) => el.name === values.brand); // .models.map((el: any) => ({ label: el.name, value: el.name }));
  console.log('currentBrand', currentBrand);

  const models: any[] = currentBrand ? currentBrand.models.map((el: any) => ({ label: el.name, value: el.name })) : [];

  useEffect(() => {
    if (!data) return;
    onChange(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Box>
        <Divider orientation="left">Установочные данные</Divider>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Марка</Box>
          <Select options={brands} value={values.brand} onChange={e => mainHandler(e, 'brand')} style={{ width: '100%' }} placeholder="Выберите марку" showSearch />
          {/* <Input value={values.brand} onChange={e => mainHandler(e.target.value, 'brand')} /> */}
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Модель</Box>
          <Select options={models} value={values.model} onChange={e => mainHandler(e, 'model')} style={{ width: '100%' }} placeholder="Выберите модель" showSearch />
          {/* <Input value={values.model} onChange={e => mainHandler(e.target.value, 'model')} /> */}
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Цвет</Box>
          <Input value={values.color} onChange={e => mainHandler(e.target.value, 'color')} />
        </Box>
      </Box>

      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Год выпуска</Box>
          <Input value={values.manufacutre_year} onChange={e => mainHandler(e.target.value, 'manufacutre_year')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">ВИН</Box>
          <Input value={values.vin} onChange={e => mainHandler(e.target.value, 'last_name')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Гос номер</Box>
          <Input value={values.gov_num} onChange={e => mainHandler(e.target.value, 'gov_num')} />
        </Box>
      </Box>
      <Box direction="row" justify="space-between">
        <Box align="center" width="30%">
          <Box width="100%">Номер прицепа</Box>
          <Input value={values.trailer_num} onChange={e => mainHandler(e.target.value, 'trailer_num')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Наклейки</Box>
          <Input value={values.stikers} onChange={e => mainHandler(e.target.value, 'stikers')} />
        </Box>
        <Box align="center" width="30%">
          <Box width="100%">Надпись на рамке</Box>
          <Input value={values.frame} onChange={e => mainHandler(e.target.value, 'frame')} />
        </Box>
      </Box>
      <Box direction="row" justify="space-between" margin={[3, 0, 0, 0]}>
        <Box align="center" width="100%">
          <Box width="100%">Особые приметы</Box>
          <Input.TextArea value={values.comment_auto} onChange={e => mainHandler(e.target.value, 'comment')} />
        </Box>
      </Box>
    </Box>
  );
};

export default TSEdit;
